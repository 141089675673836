import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  padding: 1rem; /* Padding for spacing */
  background-color: #333; /* Example background color */
  color: white; /* Example text color */
  position: fixed; /* Fix footer at the bottom */
  bottom: 0;
  width: 100%; /* Full width */
  text-align: center; /* Center text */
  
  /* Responsive Design */
  @media (max-width: 768px) {
    padding: 0.5rem; /* Reduce padding on smaller screens */
  }
`;
