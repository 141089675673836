import React from 'react';
import PropTypes from 'prop-types';
import { ContactUsWrapper } from './ContactUs.styled';

const ContactUs = () => (
  <ContactUsWrapper>
    <h2>Contact Us</h2>
    <p>If you have any questions, feel free to reach out to us through the following details:</p>
    <div style={{ marginTop: '20px' }}>
      <div style={{ marginBottom: '10px' }}>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Phone:</span>
        <a href="tel:6396582298">6396582298</a>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Email:</span>
        <a href="mailto:mohd.salman0699@gmail.com">mohd.salman0699@gmail.com</a>
      </div>
    </div>
    <p>We are available 24*7. We look forward to hearing from you!</p>
  </ContactUsWrapper>
);

ContactUs.propTypes = {};

ContactUs.defaultProps = {};

export default ContactUs;
