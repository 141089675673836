import styled from 'styled-components';

export const BannerWrapper = styled.div`
  margin-top: 5%; /* Adjust this value according to your header height */
  margin-bottom: 5%; /* Adjust this value according to your footer height */

  .slick-slider {
    width: 100%; /* Ensure the slider uses the full width */

    .slick-list {
      overflow: hidden; /* Hide overflow to ensure images don't overflow */
    }

    .slick-slide {
      quote {
        width: 100%; /* Images should fill the slide */
        height: 486px; /* Set a fixed height for the images */
        object-fit: cover; /* Crop the image to cover the slide area, maintaining aspect ratio */
        display: block; /* Removes any bottom spacing due to inline elements */
      }
    }

    .slick-dots {
      bottom: 2%px; /* Adjust this as necessary for your layout */
      margin-top: 5%;
      
      li {
        button:before {
          color: gray; /* Set the color of inactive dots to gray */
          font-size: 12px; /* Adjust the size of the dots */
          opacity: 0.75; /* Set the opacity for inactive dots */
        }

        &.slick-active button:before {
          color: #000; /* Set the color of the active dot to black */
          opacity: 1; /* Make the active dot fully visible */
        }
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 1; /* Ensure navigation buttons are above the slides */
      color: #000; /* Change arrow color if needed */
    }
  }
`;
