import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333; /* Example background color */
  color: white; /* Example text color */
  

  .nav {
    flex-grow: 1; /* Make nav grow to occupy available space */
    text-align: right; /* Align menu to the right */
  }

  ul {
    list-style: none; /* Remove bullet points */
    display: flex; /* Display items in a row */
    gap: 1rem; /* Space between menu items */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }

  li {
    /* Optionally add styles for list items */
  }

  a {
    color: white; /* Example link color */
    text-decoration: none; /* Remove underline from links */
    font-weight: bold; /* Example font weight */
    
    &:hover {
      text-decoration: underline; /* Underline on hover */
    }
  }

  .logo {
    flex-shrink: 0; /* Prevent logo from shrinking */
    margin-right: auto; /* Push the logo to the left */
  }
`;

