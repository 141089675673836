import styled from 'styled-components';

export const ContactUsWrapper = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
`;

export const ContactInfo = styled.div`
  margin-top: 20px;
`;

export const ContactItem = styled.div`
  margin-bottom: 10px;
`;

export const ContactLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;
