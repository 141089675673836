import React from 'react';
import PropTypes from 'prop-types';
import { FooterWrapper } from './Footer.styled';

const currentYear = new Date().getFullYear();
const Footer = () => (
  <FooterWrapper>
    Copyright © {currentYear}, kutesra.in. All rights reserved.
  </FooterWrapper>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
