import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'; // Import necessary router components
import './App.css';
import Header from './components/Header/Header';
import Gallery from './components/Gallery/Gallery';
import Footer from './components/Footer/Footer';
import ContactUs from './components/ContactUs/ContactUs';
import Banner from './components/Banner/Banner';

function App() {
  const location = useLocation(); // Get the current route location

  return (
    <div className="App">
      <Header />
      {/* Conditionally render the banner only on the home page ("/") */}
      {location.pathname === '/' && <Banner />}
      <Routes>
        <Route path="/" element={<div>Home</div>} /> {/* Define home page */}
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact-us" element={<ContactUs />} /> 
      </Routes>
      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
