import React, { memo } from 'react';
import Imgix from 'react-imgix';
import { GalleryContainer, ImageItem, GalleryImage } from './Gallery.styled';

const images = [
  "forest1",
  "forest2",
  "forest3",
  "mountain1",
  "mountain2",
  "mountain3",
  "river1",
  "river2",
  "river3",
];

const buildURL = (imagePath) =>
  `https://assets.imgix.net/tutorials/${imagePath}.webp`;

const Gallery = () => {
  return (
    <GalleryContainer>
      {images.map((image, index) => (
        <ImageItem key={image}>
          <Imgix
            src={buildURL(image)}
            sizes="(min-width: 1200px) 25vw, (min-width: 992px) 33vw, (min-width: 768px) 50vw, 100vw"
            imgixParams={{ fit: 'crop', ar: '1:1', auto: 'format,compress' }}
            className="gallery-image"
            htmlAttributes={{ alt: `Gallery Image ${index + 1}: ${image.replace(/\d+/, '')}` }}
          />
        </ImageItem>
      ))}
    </GalleryContainer>
  );
};

// Memoize to prevent unnecessary re-renders
export default memo(Gallery);
