import React from 'react';
import Slider from 'react-slick';
import { BannerWrapper } from './Banner.styled';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <BannerWrapper>
      <Slider {...settings}>
        <div>
          {/* <img src="/images/slide1.jfif" alt="Banner 1" /> */}
          <p className='quote'>महान सपने देखने वालों के महान सपने हमेशा पूरे होते हैं। डॉ ए.पी.जे. अब्दुल कलाम</p>
        </div>
        <div>
          {/* <img src="/images/slide2.jfif" alt="Banner 2" /> */}
          <p className='quote'>देश का सबसे अच्छा दिमाग क्लासरूम के आखिरी बेंचों पर मिल सकता है। डॉ ए.पी.जे. अब्दुल कलाम</p>
        </div>
        <div>
          {/* <img src="/images/slide3.jfif" alt="Banner 3" /> */}
          <p className='quote'>इस दुनिया में, डर की कोई जगह नहीं है। केवल ताकत ताकत का सम्मान करती है। डॉ ए.पी.जे. अब्दुल कलाम</p>
        </div>
      </Slider>
    </BannerWrapper>
  );
};

export default Banner;

