import React from 'react';
import PropTypes from 'prop-types';
import { HeaderWrapper } from './Header.styled';
import { Link } from 'react-router-dom'; // Correct Link import

const Header = () => {
  return (
    <HeaderWrapper>
      <img src="favicon.ico" alt="logo" />
      <nav className="nav">
        <ul>
          <li><Link to="/gallery">Gallery</Link></li> {/* Link to Gallery */}
          <li><Link to="/contact-us">Contact Us</Link></li> {/* Link to Contact Us */}
        </ul>
      </nav>
    </HeaderWrapper>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
